import axios, {AxiosResponse}  from 'axios'

const API_URL = process.env.REACT_APP_API_BASE_URL

//City
export const GET_CITY_URL = `${API_URL}city/get/city`
export const SAVE_CITY_URL = `${API_URL}city/save/city`
export const UPDATE_CITY_URL = `${API_URL}city/update/city`
export const DELETE_CITY_URL = `${API_URL}city/delete/city`

export function getLocalizationCity() {
    return axios.get(GET_CITY_URL)
    .then((response => response.data))
}

export function saveLocalizationCity(body:any) {
    return axios.post(SAVE_CITY_URL, body)
    .then((response => response.data))
}

export function updateLocalizationCity(id:any ,body:any) {
    return axios.put(UPDATE_CITY_URL+'/'+id, body)
    .then((response => response.data))
}

export function deleteLocalizationCity(id:any) {
    return axios.put(DELETE_CITY_URL+'/'+id)
    .then((response => response.data))
}

//State
export const GET_STATE_URL = `${API_URL}state/get/state`
export const SAVE_STATE_URL = `${API_URL}state/save/state`
export const UPDATE_STATE_URL = `${API_URL}state/update/state`
export const DELETE_STATE_URL = `${API_URL}state/delete/state`

export function getLocalizationState() {
    return axios.get(GET_STATE_URL)
    .then((response => response.data))
}

export function saveLocalizationState(body:any) {
    return axios.post(SAVE_STATE_URL, body)
    .then((response => response.data))
}

export function updateLocalizationState(id:any ,body:any) {
    return axios.put(UPDATE_STATE_URL+'/'+id, body)
    .then((response => response.data))
}

export function deleteLocalizationState(id:any) {
    return axios.put(DELETE_STATE_URL+'/'+id)
    .then((response => response.data))
}

//Country
export const GET_COUNTRY_URL = `${API_URL}country/get/country`
export const SAVE_COUNTRY_URL = `${API_URL}country/save/country`
export const UPDATE_COUNTRY_URL = `${API_URL}country/update/country`
export const DELETE_COUNTRY_URL = `${API_URL}country/delete/country`

export function getLocalizationCountry() {
    return axios.get(GET_COUNTRY_URL)
    .then((response => response.data))
}

export function saveLocalizationCountry(body:any) {
    return axios.post(SAVE_COUNTRY_URL, body)
    .then((response => response.data))
}

export function updateLocalizationCountry(id:any ,body:any) {
    return axios.put(UPDATE_COUNTRY_URL+'/'+id, body)
    .then((response => response.data))
}

export function deleteLocalizationCountry(id:any) {
    return axios.put(DELETE_COUNTRY_URL+'/'+id)
    .then((response => response.data))
}

//Currency
export const GET_CURRENCY_URL = `${API_URL}currency/get/currency`
export const SAVE_CURRENCY_URL = `${API_URL}currency/save/currency`
export const UPDATE_CURRENCY_URL = `${API_URL}currency/update/currency`
export const DELETE_CURRENCY_URL = `${API_URL}currency/delete/currency`

export function getLocalizationCurrency() {
    return axios.get(GET_CURRENCY_URL)
    .then((response => response.data))
}

export function saveLocalizationCurrency(body:any) {
    return axios.post(SAVE_CURRENCY_URL, body)
    .then((response => response.data))
}

export function updateLocalizationCurrency(id:any ,body:any) {
    return axios.put(UPDATE_CURRENCY_URL+'/'+id, body)
    .then((response => response.data))
}

export function deleteLocalizationCurrency(id:any) {
    return axios.put(DELETE_CURRENCY_URL+'/'+id)
    .then((response => response.data))
}